import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Prose from "./utility/prose";

const Footer = () => (
    <StaticQuery 
    query={graphql`
    query {
        site {
            siteMetadata {
                fullname
            }
        }
    }
    `}
    render={data => (
        <footer className="text-center bg-zinc-200 dark:bg-zinc-800 px-4 py-8">
            <Prose>
                <p><strong>Main Office</strong> (Open Tues & Thur 7am - 4pm)<br />
1000 Old Mill Road, Cedar Park, TX 78613<br />
(512) 246-0498</p>
                <p>&copy; {new Date().getFullYear()} {data.site.siteMetadata.fullname}<br />
                <Link className="text-inherit" to="/legal-notices-and-disclaimers">Legal Notices & Disclaimers</Link>
                </p>
                <small>Website provided by <a className="text-inherit" href="https://www.touchstonedistrictservices.com/" target="_blank" rel="noopener noreferrer">Touchstone</a></small>
            </Prose>
        </footer>
    )} />
)

export default Footer